import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { navigate } from "gatsby";

import PhoneInput from "react-phone-input-2";
import LayoutLanding from "../components/LayoutLanding";
import Recaptcha from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PasswordStrengthBar from "react-password-strength-bar";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
export default function WaitingLandingPage() {
  const [passwordShown, setPasswordShown] = React.useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [password, setPassword] = React.useState("");

  const handleSubmit = (e) => {
    console.log(11);
    var fullname = document.getElementById("FullName")?.value;
    var email = document.getElementById("Email")?.value;
    let PhoneNumber = document.querySelector("#Phone")?.value;
    let phoneCode = document.querySelector(".react-tel-input input")?.value;
    let password = document.querySelector("#password")?.value;
    let maincode = document.querySelector("#maincode")?.value;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNet.Cookies=KJ1AB1wXohRV_uVU-e35TejMrmfoPvZnQhaFiXM_PSB-DJSRMuhtOZ1U7mjQtNc1e8jAeTi2PEzf--xzq6cwU7Q6-OGgEESuSWGZOrU1Pra_mKVttp0Uvre3AuW3csyEJuFpCcy7ustN73pROLFYhDVzbHm1I0cfXrQIihSwkWMOj-hox1bMQfoi8Un9GGTrzakW_qdKxps1vVxYZI6X3k-yY_WQojeGV708yHgccMW0Ipon9yC-85WYBy_FdoJ-9vcHxotraJhCpekmdc1fsNcr-HT73AtyfzaxMD2MW0n_1SgFVPxUdi5HBYnfk1glImsefSAghx7E52J-F5D088wsKTMTBY8cOo_v_TgkJlf228LyVNnlcbwblmTclSPbteIk5QCOic9mr0YBNpx1cku_sDLDd6LMPtuVoukuO3wAkSu6rt9j5ZvUshjh07petqSOpZVamf8rBJPg1vDk_a25TGPEDeamq0h7i64Te3KbfioqX-sC2mh0_nWu5ujZHDyXdAWhfXu2h2mZ65jeew"
    );

    var raw = JSON.stringify({
      Email: email,
      Password: password,
      PhoneNumber: phoneCode + PhoneNumber,
      FullName: fullname,
      InvitedCode: "Bogo-" + maincode + "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/waitingList/Register`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          navigate("/waitinglist");
        }
        return response?.text() ?? "";
      })
      .then((result) => {
        var json = JSON.parse(result);
        console.log(json);

        if (json.Message === "The request is invalid.") {
          document.querySelector(".emailerror").style.display = "block";
        } else {
          document.querySelector(".emailerror").style.display = "none";
        }
        if (
          json.Message === "Code not available" ||
          json.Message === "Code not found"
        ) {
          document.querySelector(".error").style.display = "block";
        } else {
          document.querySelector(".error").style.display = "none";
        }
        if (json.access_token) {
          localStorage.setItem("waitingtoken", json.access_token);
        }
      })
      .catch((error) => console.log("error", error));
    e.preventDefault();
  };
  return (
    <LayoutLanding>
      <main>
        <Container>
          <section>
            <div className="col-xl-6 col-lg-6 col-md-12 text-center mx-auto pt-4">
              <h1 className="fs-48 fw-800 mb-5">
                Welcome to The BOGO App’s Pre-Launch Waiting List
              </h1>
              <h5 className="fw-airlight lh-28 mt-3 mb-5">
                <span className="fw-airbold fw-800">
                  There are a ton of perks, discounts and freebies just waiting
                  for you!{" "}
                </span>
                Add your info and join our exclusive invite-only Waiting List.
              </h5>
            </div>
          </section>
          <section>
            <div className="col-xl-6 col-lg-6 col-md-6 text-center mx-auto">
              <h4 className="fw-airlight">YOUR INVITE CODE</h4>
              <Card className="Card-invite mx-auto">
                <Card.Body className="py-2">
                  <Form className="py-1">
                    <InputGroup className="mx-auto">
                      <InputGroup.Text className="pe-0 border-0 bg-transparent">
                        BOGO -
                      </InputGroup.Text>
                      <FormControl
                        id="maincode"
                        placeholder="XXXXX"
                        className="ps-1"
                        maxLength={5}
                        required
                      />
                    </InputGroup>
                  </Form>
                </Card.Body>
              </Card>
              <p
                className="error"
                style={{
                  display: "none",
                  fontSize: "11px",
                  paddingTop: "4px",
                  color: "red",
                }}
              >
                error check your code again
              </p>
            </div>
          </section>
          <section className="py-5">
            <div className="col-xl-10 col-lg-10 col-md-12 mx-auto">
              <Row>
                <div className="col-xl-6 col-lg-6 col-md-12 ps-xl-5">
                  <form onSubmit={handleSubmit}>
                    <input type="hidden" name="form-name" value="information" />
                    <Form.Group
                      className="mb-4"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Full Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Full Name "
                        name="FullName"
                        id="FullName"
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-4"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Email *
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email address "
                        name="Email"
                        id="Email"
                        required
                      />
                    </Form.Group>
                    <p
                      className="emailerror"
                      style={{
                        display: "none",
                        fontSize: "11px",
                        color: "red",
                      }}
                    >
                      error email already exist
                    </p>
                    <Form.Group
                      className="mb-4"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Mobile number *
                      </Form.Label>
                      <InputGroup className="mb-4">
                        <PhoneInput
                          country="ae"
                          id="PhoneCode"
                          enableAreaCodes={true}
                        />
                        <Form.Control
                          type="tel"
                          name="Phone"
                          placeholder="Mobile number "
                          id="Phone"
                          aria-label="Text input with dropdown button"
                          required
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-4 pass-wrapper">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Create password *
                      </Form.Label>
                      <Form.Control
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={passwordShown ? "text" : "password"}
                        required
                      />
                      <i
                        role="button"
                        onClick={togglePasswordVisiblity}
                        aria-hidden="true"
                      >
                        {eye}
                      </i>
                      <PasswordStrengthBar
                        scoreWordStyle={true}
                        barColors={false}
                        password={password}
                        shortScoreWord="Short"
                        className="text-uppercase"
                      />
                    </Form.Group>

                    <Button className="w-100 fs-14 fw-bold" type="submit">
                      Submit
                    </Button>
                  </form>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12"></div>
              </Row>
            </div>
          </section>
        </Container>
      </main>
    </LayoutLanding>
  );
}
