import React, { useEffect } from 'react';
import Footer from './Footer';
import HeaderLanding from './HeaderLanding';

export default function LayoutLanding({ children }) {

  return (
    <div className="Layout">
      <HeaderLanding />
      <div className="content">{children}</div>
      <Footer />
    </div>
  )
}
