import React, { useState, useEffect } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import Logo from "./Logo";
import { navigate } from "gatsby";

export default function HeaderLanding() {
  useEffect(() => {
    if (localStorage.getItem("waitingtoken") !== null) {
      document.querySelector(".waitinglistbutton").style.display = "none";
      document.querySelector(".waitingregister").style.display = "none";
      document.querySelector(".waitinglistlogout").style.display = "initial";

    }
    if (localStorage.getItem("waitingtoken") !== null && document.URL.includes("waiting-landing")) {
      document.querySelector(".waitinglistbutton").style.display = "initial";

      document.querySelector(".waitinglistlogout").style.display = "none";

    }
    if (localStorage.getItem("waitingtoken") !== null && document.URL.includes("login-waiting")) {
      document.querySelector(".waitinglistbutton").style.display = "none";
      document.querySelector(".waitingregister").style.display = "initial";

      document.querySelector(".waitinglistlogout").style.display = "none";

    }
    if (localStorage.getItem("waitingtoken") === null && document.URL.includes("login-waiting")) {
      document.querySelector(".waitinglistbutton").style.display = "none";
      document.querySelector(".waitinglistlogout").style.display = "none";
      document.querySelector(".waitingregister").style.display = "initial";

    }
    if (localStorage.getItem("waitingtoken") === null && document.URL.includes("waiting-landing")) {
      document.querySelector(".waitinglistbutton").style.display = "initial";
      document.querySelector(".waitinglistlogout").style.display = "none";
      document.querySelector(".waitingregister").style.display = "none";
    }
  }, []);
  function Logout() {
    localStorage.clear();
    navigate("/waiting-landing");
  }
  return (
    <header>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="white"
        className="py-xl-5"
      >
        <Container>
          <Navbar.Brand href="/">
            <Logo />
          </Navbar.Brand>
          <Nav className="ms-auto align-items-center ">
            <div className="welocmetext">
              <Button href="#" className="ms-3 nav-button waitinglistlogout" style={{ display: "none" }} onClick={() => {
                Logout();
              }}>
                Logout
              </Button>
              <Button href="/waiting-landing" className="ms-3 nav-button waitingregister" style={{ display: "none" }}>
                Waiting List Register
              </Button>
              <Button href="/login-waiting" className="ms-3 nav-button waitinglistbutton" style={{ display: "none" }}>
                Waiting List Login
              </Button>
              <Button href="/" className="ms-3 nav-button ">
                Go to website
              </Button>

            </div>
          </Nav>
        </Container>
      </Navbar>
    </header>
  );
}
